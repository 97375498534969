<template>
  <div class="main">
    <div class="container">
      <!-- CARD TABLE -->
      <div class="card-table">
        <div class="card-row">
          <!-- CARDS DIV -->
          <Card
            v-for="(card, index) in dealtCards"
            :key="card.id"
            :card="card"
            :cardWidthPx="cardWidthPx"
            :cardHeightPx="cardHeightPx"
            @mousedown="dragMouseDown($event, card.id, index)"
            @dblclick="revealCard($event, index)"
            @contextmenu="openCardContextMenu()"
            v-on:callDiscard="discard($event, card.id, index)"
          ></Card>
        </div>
      </div>
      <!-- SIDEBAR -->
      <div class="sidebar">
        <!-- CONSTRAINTS SECTION -->
        <div class="constraints-div">
          <div class="constraints-div-header">Categories</div>
          <div
            class="deal-btn"
            v-for="(cat, id) in constraintCategories"
            :key="id"
          >
            <div
              class="deal-ico"
              @click="dealCard(id)"
              :style="{
                'background-color': cat.color1,
                'border-top-color': cat.color2,
              }"
            ></div>
            <div class="category-label" @click="dealCard(id)">
              {{ cat.name }}
              <!-- ,{{ cat.cardsRemaining }} -->
            </div>
          </div>
        </div>
        <!-- ROLE(S) SECTION -->
        <div class="identity-div">
          <div class="deal-btn" v-for="(cat, id) in roleCategories" :key="id">
            <div
              class="deal-ico"
              @mousedown="dealCard(id)"
              :style="{
                'background-color': cat.color1,
                'border-top-color': cat.color2,
              }"
            ></div>

            <div class="category-label" @mousedown="dealCard(id)">
              {{ cat.name }}
              <!-- ,{{ cat.cardsRemaining }} -->
            </div>
          </div>
        </div>
        <!-- DISCARDED SECTION -->
        <div class="discarded-div" :class="{ disabled: noDiscardedCards }">
          <div class="deal-btn">
            <img
              class="discard-ico"
              src="../assets/Trashcan.png"
              @click="undoDiscard()"
            />
            <div class="category-label" @click="undoDiscard()">
              Restore Discarded Card
            </div>
          </div>
        </div>
        <div class="reset-game-div">
          <!-- RESET ALL BUTTON -->
          <div id="reset-all-btn" @mousedown="showConfirmResetModal()">
            Shuffle &amp; Reset
          </div>
        </div>
      </div>
      <!-- GUIDE BOOK -->
      <div v-if="guideOpen" id="guide-book" class="guide-book">
        <div class="header" @mousedown="guidebookDragMouseDown($event)">
          <div class="guidebook-move-header">
            <img
              class="move-handle"
              src="../assets/guide-book-move-handle.png"
            />
            <h1>GUIDE BOOK</h1>
          </div>
          <div class="close-btn" @mousedown="hideGuideBook()">close</div>
        </div>
        <div class="content">
          <!-- BEGIN HOLDER GUIDE BOOK -->
          <!-- Welcome modal &amp; guide book matter goes here. -->
          <!-- <h1 v-if="gameNotStarted" class="welcome-prompt">
            Click to deal cards ➞
          </h1> -->
          <h1 id="investing-in-futures-original-deck-workshop-guide">
            Investing in Futures: Original Deck Workshop Guide
          </h1>
          <h2
            id="this-guide-contains-the-bare-bones-instructions-you-need-to-play."
          >
            This guide contains the bare-bones instructions you need to play.
          </h2>
          <p>
            If you are looking for a more structured facilitation script, see
            Extended Resources.
          </p>
          <h2 id="about-this-guide">About this Guide</h2>
          <p>
            Investing in Futures can be played in a number of ways: alone, in a
            small group, and in groups of groups! This guide includes
            instructions for the original workshop experience, but feel free to
            use the cards in whatever way suits you.
          </p>
          <p>
            In this guide, there is a sequence of instructions. No need to read
            all of them at once - you can play as you go!
          </p>
          <p>
            A group is best made up of 1-4 players. If you are with more than 4
            people, multiple groups can play at the same time: each group draws
            its own set of cards. Share what you made at the end!
          </p>
          <h2 id="set-up">Set-up</h2>
          <p>
            As a group, identify 6 different categories, and choose one card
            from each (do not include a “Role” card, that comes later).
          </p>
          <h2 id="part-i-worldbuilding">Part I: Worldbuilding</h2>
          <h2 id="suggested-time-for-part-i-45-minutes">
            Suggested Time for Part I: 45 Minutes
          </h2>
          <h3 id="world-building-instructions">World Building Instructions</h3>
          <ul>
            <li>
              Imagine a future that you, as a group, want to live in that
              reflects the constraints described on your cards.
            </li>
            <li>
              Do any of the cards bring up a vivid image or feeling for you?
            </li>
            <li>
              Feel free to look up and debate the meaning of your cards to reach
              a common understanding. You can be creative in the interpretation
              of your card to suit the desires of your group members.
            </li>
            <li>
              Try organizing the cards into groups and diagramming their
              relationships.
            </li>
            <li>
              <strong
                ><em
                  >Your goal in this section is to write a basic description of
                  your world?</em
                ></strong
              >
            </li>
          </ul>
          <h3 id="world-building-prompts">World Building Prompts</h3>
          <h4 id="you-might-want-to-consider">You might want to consider…</h4>
          <h5 id="the-big-picture">The big picture</h5>
          <ul>
            <li>How big is your world?</li>
            <li>Is this present-day, or in the future? How far?</li>
            <li>
              What would you like to experience in your world that does not
              exist in the present?
            </li>
            <li>
              What ecosystem are you in? Who is there with you (plants, animals,
              etc)?
            </li>
            <li>Are there neighborhoods?</li>
            <li>Are there other worlds/communities nearby?</li>
            <li>Are there seasons?</li>
            <li>What are the land and waterscapes like?</li>
            <li>What is your energy source?</li>
            <li>What kinds of infrastructure do you need?</li>
          </ul>
          <h5 id="everyday-concerns">Everyday concerns</h5>
          <ul>
            <li>What is your family structure?</li>
            <li>What do you eat and where/how do you get it?</li>
            <li>Do you use transportation?</li>
            <li>Do you move or trade goods or use currency?</li>
            <li>What materials, fashion, or uniforms do you wear?</li>
          </ul>
          <h5 id="marking-time">Marking time</h5>
          <ul>
            <li>How is time valued?</li>
            <li>What do you celebrate? Do you have holidays?</li>
          </ul>
          <h5 id="leadership-and-civic-life">Leadership and civic life</h5>
          <ul>
            <li>What are the core values which uphold this system?</li>
            <li>What are the laws which uphold this system?</li>
            <li>How does leadership communicate with its constituency?</li>
          </ul>
          <h5 id="troubles-and-interventions">Troubles and interventions</h5>
          <ul>
            <li>What makes your community healthy and safe?</li>
            <li>What happens if someone gets sick?</li>
            <li>
              How do you deal with bias, strife, tribalism, conflict? What forms
              does it take?
            </li>
            <li>Is there such a thing as “criminal activity?”</li>
          </ul>
          <p>
            There are other categories that you did <em>not</em> choose a card
            from. Without drawing any more cards, ask how those categories
            manifest in your world?
          </p>
          <h2 id="part-ii-inhabit-your-world">Part II: Inhabit your world</h2>
          <h2 id="suggested-time-for-part-ii-45-minutes">
            Suggested Time for Part II: 45 Minutes
          </h2>
          <h3 id="choose-a-role">Choose a Role</h3>
          <p>
            Each group member should pick a Role card. You can also invent one
            instead.
          </p>
          <h3 id="write-a-narrative">Write a Narrative</h3>
          <p>
            In this section, you will assume the role you chose, and on your
            own, write about the world from your perspective in that role.
          </p>
          <h4 id="you-might-want-to">You might want to…</h4>
          <ul>
            <li>Write a diary entry</li>
            <li>Write a letter to an old friend</li>
            <li>Describe what you do on an important holiday</li>
            <li>Write an open letter to a political leader</li>
            <li>Write a shopping list</li>
            <li>Write up a meeting agenda</li>
            <li>Write about a really bad day</li>
            <li>Write about a really great day</li>
            <li>Write a to-do list</li>
          </ul>
          <p>
            <strong
              ><em
                >Share back with each other, and synthesize these details with
                the description of your world.
              </em></strong
            >
          </p>
          <h3 id="make-an-artifact">Make an Artifact</h3>
          <p>
            As a group or on your own, use materials you have available to
            create a significant cultural object or ritual from your world. You
            can approximate or use stand-in materials. For instance, you can use
            spaghetti as worms, or make a building out of paper. You could also
            sing a song or do a dance.
          </p>
          <p>
            You might want to write a description of your artifact to give it
            context.
          </p>
          <h4 id="some-suggestions-for-forms">Some suggestions for forms:</h4>
          <ul>
            <li>
              A logo or image that encapsulates the system you are describing
            </li>
            <li>A storyboard of action and feeling of your world</li>
            <li>Make a protest sign</li>
            <li>Dress in the style of your identity</li>
            <li>Make a snack from your world</li>
            <li>Sing an anthem of your world</li>
            <li>Create a folk dance</li>
            <li>Wave your flag or sigil</li>
            <li>Unveil a sacred relic</li>
            <li>Perform a sacred ritual</li>
            <li>Draw an engineering schematic of an important structure</li>
            <li>Mint the currency you use</li>
            <li>Give a public speech</li>
          </ul>
          <h2 id="part-iii-tell-the-story-of-your-world">
            Part III: Tell the story of your world
          </h2>
          <h2 id="suggested-time-for-part-iii-30-minutes">
            Suggested Time for Part III: 30 Minutes
          </h2>
          <p>
            Share your world with others, using your artifacts to help tell your
            story!
          </p>

          <!-- END HOLDER GUIDE BOOK -->
        </div>
      </div>
      <div v-if="!guideOpen" id="guide-book-btn" @mousedown="showGuideBook()">
        Open Guide Book
      </div>
      <!-- RESET ALL CONFIRMATION MODAL -->
      <div
        v-if="resetModalOpen"
        id="modal-screen-dimmer"
        @mousedown="hideConfirmResetModal()"
      ></div>
      <div v-if="resetModalOpen" id="exit-confirmation-modal">
        <div class="modal-header">
          <div class="exit-modal-title">WARNING</div>
          <div class="close-btn" @mousedown="hideConfirmResetModal()">
            close
          </div>
        </div>
        <div class="exit-modal-text">
          <p>This will clear all existing cards and shuffle the deck.</p>
          <p>Do you want to proceed?</p>
        </div>
        <div class="btn-container">
          <button class="cancel-btn" @mousedown="hideConfirmResetModal()">
            Cancel
          </button>
          <button class="confirm-btn" @mousedown="resetGame()">
            Yes, Reset All
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { getDeck } from "@/services/ExampleService.js";
import Card from "@/components/Card.vue";

export default {
  name: "Game Main",
  data() {
    return {
      deckId: this.$route.params.deckId,
      gameStarted: false,
      guideOpen: true,
      resetModalOpen: false,
      deck: [
        {
          // Template of a Category
          id: String,
          name: String,
          color1: String,
          color2: String,
          cards: [
            // Template of a Card
            {
              id: String,
              text: String,
              dealt: Boolean,
            },
          ],
        },
      ],
      roleCategories: {},
      constraintCategories: {},
      cardsPerRow: null,
      maxRows: null,
      maxCardsOnTable: null,
      currentRow: null,
      cardsGridLayer: null,
      dealtCards: [
        // {
        //   id: String,
        //   revealed: Boolean,
        //   name: String,
        //   svgURL: String,
        //   color1: String,
        //   color2: String,
        //   category: String,
        //   text: String,
        //   left: String,
        //   top: String,
        //   z : String,
        // },
      ],
      numDealtCards: 0,
      discardedCards: [],
      // Values for positioning cards
      cardHeight: 220,
      cardWidth: 150,
      cardMargins: 10,
      gameTableMargins: 40,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
        targetId: undefined,
        targetIndex: 0,
        topZ: 100,
      },
    };
  },
  components: {
    Card,
  },
  methods: {
    fetchNewDeck() {
      getDeck(this.deckId)
        .then((theData) => {
          this.setupCategoriesAndRoles(theData);
          //this.deck = theData;
          // console.log("Data keys: ", Object.keys(this.deck));
          // console.log(this.deck);
        })
        .catch((err) => {
          console.log("Oh noes, something went wrong: ", err);
        });
    },
    setupCategoriesAndRoles(theRawData) {
      console.log("setting up categories and roles");
      this.deck = theRawData;
      let keys = Object.keys(this.deck);
      for (var i = 0; i < keys.length; i++) {
        let currentKey = keys[i];
        if (this.deck[currentKey].name === "Role") {
          this.roleCategories[currentKey] = this.deck[currentKey];
        } else {
          this.constraintCategories[currentKey] = this.deck[currentKey];
        }
      }
    },
    setupDealtCardCounts() {
      console.log("setupDealtCardCounts() called.");
      let keys = Object.keys(this.deck);
      for (var i = 0; i < keys.length; i++) {
        let currentKey = keys[i];
        this.deck[currentKey].cardsDealt = 0;
        this.deck[currentKey].cardsRemaining = this.deck[
          currentKey
        ].cards.length;
        // console.log(
        //   "Cards dealt in",
        //   this.deck[currentKey].name,
        //   ": ",
        //   this.deck[currentKey].cardsDealt,
        //   " of ",
        //   this.deck[currentKey].cardsRemaining
        // );
      }
    },
    dealCard(catId) {
      // if card counts have not been set up, do it now
      if (this.deck[catId].cardsDealt == undefined) {
        //mark game as started
        this.gameStarted = true;
        this.setupDealtCardCounts();
      }
      //Check if there's anymore cards to deal
      if (this.deck[catId].cardsRemaining <= 0) {
        alert("No more cards to deal in this deck.");
      } else {
        //1. pick a card at random
        const random = Math.floor(
          Math.random() * this.deck[catId].cardsRemaining
        );
        //2. create copy and add to global dealtCard array
        this.numDealtCards = this.numDealtCards + 1.0;
        // console.log("Incrementing numdealtCards: " + this.numDealtCards);
        let thisCard = {};
        thisCard.revealed = false;
        thisCard.category = this.deck[catId].name;
        thisCard.svgURL = this.deck[catId].svgURL;
        thisCard.color1 = this.deck[catId].color1;
        thisCard.color2 = this.deck[catId].color2;
        thisCard.text = this.deck[catId].cards[random].text;
        thisCard.id = this.deck[catId].cards[random].id;
        // TODO: Need to acutally make this work, and fix spacing units
        // Place cards on table
        let cardPos = this.placeCard(this.numDealtCards);
        thisCard.left = cardPos.x + "px";
        thisCard.top = cardPos.y + "px";
        // Push this card to dealtCards array
        this.dealtCards.push(thisCard);
        // console.log(this.dealtCards);
        //2. mark as dealt and move it to the back of the array
        this.deck[catId].cards[random].dealt = true;
        this.deck[catId].cards[random].dealOrder = this.deck[catId].cardsDealt;
        // console.log(
        //   'Category: "',
        //   this.deck[catId].name,
        //   '" Text: "',
        //   this.deck[catId].cards[random].text,
        //   '", dealt: ',
        //   this.deck[catId].cards[random].dealt,
        //   ", dealOrder: ",
        //   this.deck[catId].cards[random].dealOrder
        // );
        this.deck[catId].cardsDealt++;
        this.deck[catId].cardsRemaining--;
        let chosenCard = this.deck[catId].cards.splice(random, 1)[0];
        this.deck[catId].cards.push(chosenCard);
      }
    },
    placeCard: function (dealtCardNumber) {
      // Card dimensions and margins are set in Data():
      // cardHeight: __,
      // cardWidth: __,
      // cardMargins: __,
      // gameTableMargins: __,
      let gameTableWidth = window.innerWidth * 0.8;
      let gameTableHeight = window.innerHeight * 0.8;
      // If cardsPerRow not set, calculate now ----------------
      if (this.cardsPerRow === null) {
        this.cardsPerRow = Math.floor(
          (gameTableWidth - 2 * this.gameTableMargins) /
            (this.cardWidth + 2 * this.cardMargins)
        );
      }
      // If maxRows not set, calculate now ----------------
      if (this.maxRows === null) {
        this.maxRows = Math.floor(
          (gameTableHeight - 2 * this.gameTableMargins) /
            (this.cardHeight + 2 * this.cardMargins)
        );
      }
      // If maxCardsOnTable not set, calculate now ----------------
      if (this.maxCardsOnTable === null) {
        this.maxCardsOnTable = this.maxRows * this.cardsPerRow;
      }
      //Calculate additional margins needed to center cards ----------------
      let additionalMargin = Math.floor(
        0.5 *
          (gameTableWidth -
            2 * this.gameTableMargins -
            this.cardsPerRow * (this.cardWidth + 2 * this.cardMargins))
      );
      // Calculate card position: which layer? ----------------
      // Initialize cardLayer if not initialized
      if (this.cardsGridLayer === null) {
        this.cardsGridLayer = 0;
      }
      if (dealtCardNumber < this.maxCardsOnTable) {
        this.cardsGridLayer = 0;
      } else if (dealtCardNumber % this.maxCardsOnTable === 0) {
        this.cardsGridLayer =
          Math.floor(dealtCardNumber / this.maxCardsOnTable) - 1;
      } else {
        this.cardsGridLayer = Math.floor(
          dealtCardNumber / this.maxCardsOnTable
        );
      }
      // calculate card position: which row? ----------------
      // Initialize currentRow if not initialized
      if (this.currentRow === null) {
        this.currentRow = 0;
      }

      // TODO: REMOVE THIS CODE CHUNK IF NEW CARD PLACEMENT WORKS
      let rowNumber;
      if (dealtCardNumber <= this.cardsPerRow) {
        rowNumber = 0;
      } else if (dealtCardNumber % this.cardsPerRow === 0) {
        rowNumber = Math.floor(dealtCardNumber / this.cardsPerRow) - 1;
      } else {
        rowNumber = Math.floor(dealtCardNumber / this.cardsPerRow);
      }
      // console.log(
      //   "dealtCardNumber: " + dealtCardNumber + ", rowNumber: " + rowNumber
      // );
      if (rowNumber >= this.maxRows) {
        this.currentRow = rowNumber % this.maxRows;
      } else {
        this.currentRow = rowNumber;
      }
      let yPos =
        this.gameTableMargins +
        (this.cardHeight + this.cardMargins) * this.currentRow +
        this.cardsGridLayer * this.cardMargins;

      // calculate card position: which column?
      let colNumber;
      if (dealtCardNumber <= this.cardsPerRow) {
        colNumber = dealtCardNumber - 1;
        // console.log(
        //   "colNumber: " + colNumber + ", this.cardsPerRow: " + this.cardsPerRow
        // );
      } else if (dealtCardNumber % this.cardsPerRow === 0) {
        colNumber = this.cardsPerRow - 1;
      } else {
        colNumber = Math.floor(dealtCardNumber % this.cardsPerRow) - 1;
      }
      let xPos =
        this.gameTableMargins +
        additionalMargin +
        (this.cardWidth + this.cardMargins) * colNumber +
        this.cardsGridLayer * this.cardMargins;
      var cardPos = { x: xPos, y: yPos };
      // console.log("xPos = " + xPos + ", yPos = " + yPos);
      return cardPos;
    },
    revealCard: function (event, cardIndex) {
      event.preventDefault();
      this.dealtCards[cardIndex].revealed = true;
      console.log(
        "Revealing card index: ",
        cardIndex,
        ", ",
        this.dealtCards[cardIndex].text
      );
    },
    // CARD DISCARDING FUNCTIONS  -------------------------
    openCardDiscardMenu: function () {},
    discard: function (event, cardId, cardIndex) {
      // event.preventDefault();
      let cardToDiscard = this.dealtCards[cardIndex];
      this.dealtCards.splice(cardIndex, 1);
      this.discardedCards.push(cardToDiscard);
      console.log("Discarded: ", cardId, " cardIndex:", cardIndex);
    },
    undoDiscard: function () {
      //console.log("discarded cards pile:" + this.discardedCards);
      let cardToRestore = this.discardedCards[this.discardedCards.length - 1];
      this.dealtCards.push(cardToRestore);
      this.discardedCards.pop();
      //TODO: Check if there are any more cards to restore
    },
    // GUIDE BOOK FUNCTIONS -------------------------
    showGuideBook() {
      this.guideOpen = true;
    },
    hideGuideBook() {
      this.guideOpen = false;
    },
    guidebookDragMouseDown: function (event) {
      event.preventDefault();
      // get the mous cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.guidebookDrag;
      document.onmouseup = this.closeDragElement;
    },
    guidebookDrag: function (event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      // TODO: Set max drag area to within window limits
      document.getElementById("guide-book").style.position = "absolute";
      document.getElementById("guide-book").style.top =
        document.getElementById("guide-book").offsetTop -
        this.positions.movementY +
        "px";
      document.getElementById("guide-book").style.bottom = "auto";
      document.getElementById("guide-book").style.left =
        document.getElementById("guide-book").offsetLeft -
        this.positions.movementX +
        "px";
      // document.getElementById("guide-book").style.top = 100 + "px";
      // document.getElementById("guide-book").style.left = 100 + "px";
    },
    // CARD DRAGGING FUNCTIONS -------------------------
    dragMouseDown: function (event, id, index) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      this.positions.targetId = id.toString();
      this.positions.targetIndex = index;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (event) {
      // console.log("elementDrag() called");
      // console.log("targetId:", this.positions.targetId);
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      document.getElementById(this.positions.targetId).style.position =
        "absolute";
      this.dealtCards[this.positions.targetIndex].z = this.positions.topZ;
      this.positions.topZ++;
      this.dealtCards[this.positions.targetIndex].top =
        document.getElementById(this.positions.targetId).offsetTop -
        this.positions.movementY +
        "px";
      this.dealtCards[this.positions.targetIndex].left =
        document.getElementById(this.positions.targetId).offsetLeft -
        this.positions.movementX +
        "px";
    },
    // closeDragElement() is used by both card and guidebook drag functions
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
    // FUNCTIONS FOR RESETTING GAME  -------------------------
    showConfirmResetModal: function () {
      if (this.resetModalOpen === false) {
        this.resetModalOpen = true;
      }
    },
    hideConfirmResetModal: function () {
      if (this.resetModalOpen === true) {
        this.resetModalOpen = false;
      }
    },
    resetGame: function () {
      window.location.reload();
    },
  },
  computed: {
    gameNotStarted: function () {
      return !this.gameStarted;
    },
    currentDeckId() {
      return this.$route.params.deckId;
    },
    cardWidthPx: function () {
      return this.cardWidth + "px";
    },
    cardHeightPx: function () {
      return this.cardHeight + "px";
    },
    noDiscardedCards: function () {
      if (this.discardedCards.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.fetchNewDeck();
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100vh;
  padding-top: 3rem;
  box-sizing: border-box;
}

/* Table Area */
.card-table {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 8;
  overflow: scroll;
  box-sizing: border-box;
  padding: 1rem;
}
.card-row {
  display: flex;
  flex-direction: row;
  flex-grow: 8;
}
.card-row.identity-row {
  flex-grow: 2;
}
.card-col {
  display: flex;
  flex-direction: column;
}

/* Sidebar Area */
.sidebar {
  box-sizing: border-box;
  background-color: var(--var-IIF-yellow);
  width: 20%;
  max-height: calc(100vh - 4.5rem);
  flex-direction: row;
  position: fixed;
  right: 0;
  bottom: 0;
  height: 100%;
  border-left: 4px solid var(--var-IIF-teal);
}
.constraints-div {
  overflow: scroll;
  height: 70%;
  box-sizing: border-box;
}
.constraints-div-header {
  font-family: var(--var-IIF-sans-heavy);
  color: var(--var-IIF-teal);
  border-bottom: 1px solid var(--var-IIF-teal);
  text-align: center;
  text-transform: uppercase;
  padding: 0.5rem 1rem 0.1rem 1rem;
  margin: 0 1rem 0.5rem 1rem;
}
.identity-div {
  height: 10%;
  overflow: scroll;
  box-sizing: border-box;
  /* background-color: #e8cc48; */
  background-color: var(--var-IIF-yellow);
  border-top: 4px solid var(--var-IIF-teal);
}
.discarded-div {
  height: 10%;
  overflow: scroll;
  box-sizing: border-box;
  /* background-color: #d7d5b6; */
  border-top: 4px solid var(--var-IIF-teal);
  background-color: #e8cc48;
}
.discarded-div.disabled {
  opacity: 0.5;
}
.discarded-div.disabled .deal-btn .deal-ico.discarded,
.discarded-div.disabled .deal-btn .category-label {
  cursor: not-allowed;
}
.reset-game-div {
  /* background-color: #d7d5b6; */
  height: 10%;
  box-sizing: border-box;
  background-color: var(--var-IIF-teal);
  padding: 1rem;
  display: flex;
  flex-direction: column-reverse;
}
.fluidBox {
  display: flex;
  background-color: grey;
  margin-bottom: 20px;
}
.deal-btn {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.deal-ico {
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  margin: 0.5rem 1rem;
  background-color: aliceblue;
  border-radius: 0.4rem;
  border-top: 2rem solid gold;
}
.deal-ico:hover {
  box-shadow: 0.1rem 0.1rem 0 #333;
}
.deal-ico:active {
  position: relative;
  top: 0.1rem;
  left: 0.1rem;
  box-shadow: none;
}
.deal-ico.discarded {
  position: relative;
  top: 0.3rem;
  transform: rotate(15deg);
}
.discard-ico {
  height: 5rem;
  margin: 0.5rem 1rem;
}
.category-label {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.8rem;
  text-align: right;
}
.category-label:hover {
  cursor: pointer;
  text-decoration: underline;
}
.category-label:active {
  cursor: pointer;
  text-decoration: underline;
  color: var(--var-IIF-blue);
}

/* Guide Book Btn */
#guide-book-btn {
  font-family: var(--var-IIF-sans-heavy);
  border: 1px solid blue;
  border-radius: 2rem;
  background-color: white;
  color: blue;
  position: fixed;
  padding: 1rem 1.5rem 0.5rem 1.5rem;
  bottom: 2rem;
  left: 2rem;
  text-transform: uppercase;
}
#guide-book-btn:hover {
  background-color: blue;
  color: white;
  transition-duration: 0.6s;
}
#guide-book-btn:active {
  color: var(--var-IIF-yellow);
  transition-duration: 0s;
}

/* Guide Book Pop Up */

.guide-book {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  background-color: white;
  border: 1px solid var(--var-IIF-blue);
  border-radius: 1rem;
  padding: 0;
  z-index: 2000;
  overflow: hidden;
  box-shadow: 0.25rem 0.25rem 0 var(--var-IIF-teal);
}
.guide-book .header {
  cursor: move;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--var-IIF-blue);
  color: var(--var-IIF-yellow);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  box-sizing: border-box;
}
.guidebook-move-header {
  cursor: move;
  display: flex;
  height: 100%;
  align-items: center;
}
.move-handle {
  height: 1rem;
  margin-right: 0.5rem;
  margin-top: -0.3rem;
}
.guide-book .header h1 {
  font-family: var(--var-IIF-sans-heavy);
  font-size: 1.2rem;
  margin: 0;
}
.guide-book .close-btn {
  font-family: var(--var-IIF-sans-heavy);
  cursor: pointer;
}
.guide-book .content {
  height: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 3rem 2rem 2rem 2rem;
  text-align: left;
  color: black;
  height: 45vh;
  width: 73vw;
  resize: both;
  background-color: white;
}
/* Guidebook Content Markdown Typography */
.guide-book .content h1,
.guide-book .content h2,
.guide-book .content h5 {
  text-transform: uppercase;
}
.guide-book .content h3,
.guide-book .content h4 {
  font-family: var(--var-IIF-sans-heavy);
}
.guide-book .content h1 {
  font-size: 2.4rem;
  color: var(--var-IIF-blue);
}
h1.welcome-prompt {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: lighter;
  color: var(--var-IIF-teal) !important;
  text-transform: uppercase;
  font-style: italic;
  text-align: right;
}
.guide-book .content h2 {
  margin-top: 2.2rem;
  margin-bottom: 0.8rem;
}
.guide-book .content h3 {
  color: var(--var-IIF-blue);
  background-color: var(--var-IIF-yellow);
}
.guide-book .content h4 {
}
.guide-book .content h5 {
  font-size: 1.2rem;
}
.guide-book .content p {
  margin-bottom: 1rem;
}
.guide-book .content p,
.guide-book .content ul li {
  font-family: Arial, Helvetica, sans-serif;
}
.guide-book .content ul {
  margin-bottom: 3rem;
}
.guide-book .content ul li {
  margin-bottom: 0.75rem;
}

/* Reset All Btn */
#reset-all-btn {
  cursor: pointer;
  font-family: var(--var-IIF-sans-heavy);
  border: 1px solid var(--var-IIF-red);
  border-radius: 2rem;
  background-color: white;
  color: var(--var-IIF-red);
  padding: 1rem 1.5rem 0.5rem 1.5rem;
  text-transform: uppercase;
  text-align: center;
}
#reset-all-btn:hover {
  background-color: var(--var-IIF-red);
  color: white;
  transition: background-color 0.3s;
}
#reset-all-btn:active {
  color: var(--var-IIF-yellow);
  transition-duration: 0s;
}
/* Exit Confirmation Modal */
#modal-screen-dimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2001;
  background-color: rgba(0, 0, 0, 0.5);
}
#exit-confirmation-modal {
  position: absolute;
  width: 50vw;
  top: 25vh;
  left: 25vw;
  margin: 0 auto;
  background-color: white;
  color: black;
  box-sizing: border-box;
  border-radius: 0.5rem;
  z-index: 2002;
}
#exit-confirmation-modal .modal-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: var(--var-IIF-red);
  color: white;
  box-sizing: border-box;
  padding: 1rem 2rem 0.5rem 2rem;
  border-radius: 0.5rem 0.5rem 0 0;
}
.modal-header .exit-modal-title {
  font-family: var(--var-IIF-sans-heavy);
  font-size: 2.2rem;
}
.modal-header .close-btn {
  cursor: pointer;
  font-family: var(--var-IIF-sans-heavy);
  font-size: 2.2rem;
}
.exit-modal-text {
  box-sizing: border-box;
  padding: 1rem 2rem 0 2rem;
  font-family: var(--var-IIF-sans-body);
  font-weight: bold;
  font-size: 2.2rem;
}
#exit-confirmation-modal .btn-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 2rem;
}
#exit-confirmation-modal .btn-container button {
  cursor: pointer;
  background-color: white;
  font-family: var(--var-IIF-sans-heavy);
  padding: 1rem 1.5rem 0.5rem 1.5rem;
  text-transform: uppercase;
  border-radius: 2rem;
  border: 1px solid black;
}
#exit-confirmation-modal .btn-container button.cancel-btn {
  background-color: white;
  color: #333;
  border: 1px solid #333;
}
#exit-confirmation-modal .btn-container button.cancel-btn:hover {
  background-color: #333;
  color: white;
}
#exit-confirmation-modal .btn-container button.confirm-btn {
  background-color: red;
  color: white;
  border: 1px solid white;
}
#exit-confirmation-modal .btn-container button.confirm-btn:hover {
  color: var(--var-IIF-yellow);
  border: 1px solid red;
}
</style>
