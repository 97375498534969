<template>
  <div
    class="card-wrapper"
    :style="{
      width: cardWidthPx,
      height: cardHeightPx,
      top: card.top,
      left: card.left,
      'z-index': card.z,
    }"
    :key="card.key"
    :id="card.id"
    :ref="card.id"
  >
    <div class="card-inner" :class="card.revealed ? 'revealed' : ''">
      <!-- CARD INFOSIDE -->
      <div
        class="card card-infoside"
        :style="{
          'background-color': card.color1,
          color: card.color2,
        }"
      >
        <div
          v-if="card.svgURL"
          class="card-icon"
          :style="{
            'background-image': 'url(' + card.svgURL + ')',
          }"
        ></div>
        <div class="card-header" :style="{ 'background-color': card.color2 }">
          <h1 :style="{ color: card.color1 }">
            {{ card.category }}
          </h1>
          <div class="card-delete-btn" @mousedown="$emit('callDiscard')">x</div>
        </div>
        <div class="card-text-box">
          <h5>{{ card.text }}</h5>
        </div>
      </div>
      <!-- CARD BACKSIDE -->
      <div
        class="card card-backside"
        :style="{
          'background-image': 'url(' + card.svgURL + ')',
          'background-color': card.color1,
          color: card.color2,
        }"
      >
        <div class="card-footer" :style="{ 'background-color': card.color2 }">
          <h1 :style="{ color: card.color1 }">
            {{ card.category }}
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    card: {
      id: String,
      revealed: Boolean,
      name: String,
      svgURL: String,
      color1: String,
      color2: String,
      category: String,
      text: String,
      left: String,
      top: String,
      z: String,
    },
    cardWidthPx: String,
    cardHeightPx: String,
  },
  emits: ["callDiscard"],
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-wrapper {
  position: absolute;
  background-color: transparent;
  /* Fallback only; Card dimensions & margins are set dynamically in Data() */
  width: 120px;
  height: 170px;
  /* For 3D effect */
  perspective: 1000px;
}
.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.card-inner.revealed {
  transform: rotateY(180deg);
}

.card {
  cursor: move;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  margin: 0;
  background-color: limegreen;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.3);
}
.card-infoside {
  transform: rotateY(180deg);
}
.card-backside {
  /* fallback background color */
  background-color: limegreen;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 85%;
  background-position-y: 25%;
  display: flex;
  flex-direction: column-reverse;
}
.card-backside .card-footer {
  display: flex;
  height: 15%;
  margin-top: 0;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  /* fallback background color */
  background-color: darkgreen;
  color: yellow;
}

.card-infoside .card-icon {
  position: absolute;
  left: 0;
  top: 10%;
  height: 100%;
  width: 100%;
  opacity: 0.1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 85%;
  /* TODO: add default background image */
  /* background-image: url(); */
}
.card-infoside .card-header {
  display: flex;
  height: 15%;
  margin-top: 0;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: darkgreen;
  color: yellow;
}
.card-infoside h1,
.card-backside h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  padding: 0;
}
.card-delete-btn {
  cursor: pointer;
  color: red;
  font-weight: bolder;
  position: fixed;
  top: 5px;
  right: 5px;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  text-align: center;
}
.card-delete-btn:hover {
  background-color: red;
  color: white;
}
.card-delete-btn:active {
  border: 1px solid white;
}
.card-infoside .card-text-box {
  position: relative;
  display: flex;
  height: 85%;
  margin-top: 0;
  padding: 0 0.5rem;
  align-items: center;
  justify-content: center;
}
.card-infoside h5 {
  display: inline-block;
  font-size: 2.2rem;
  text-align: center;
  padding-bottom: 1rem;
}
</style>
